import axios, { ResponseType } from 'axios';

// Ducks
import { updateSessionErrorCount } from '@/ducks/user';

const CancelToken = axios.CancelToken;

export const getCancelToken = () => CancelToken.source();

const axiosInstance = axios.create( {
    withCredentials: true
    , headers: {
        'Authorization': `JWT ${ process.env.NEXT_PUBLIC_BE_KEY }`
        , 'Content-Type': 'application/json'
    }
    , baseURL: process.env.NEXT_PUBLIC_IS_PREVIEW === 'true' ? process.env.NEXT_PUBLIC_PREVIEW_BE_URL : process.env.NEXT_PUBLIC_BE_URL
} );

interface makeAPICallProps<TPayload> {
    url: string;
    method?: string;
    data?: TPayload;
    cancelToken?: any;
    responseType?: ResponseType;
    isBFFEndpoint?: boolean;
    errorPrefixMessage?: string;
}
export const makeAPICall = async <TData = any, TPayload = any>( {
    method
    , url
    , data
    , cancelToken
    , responseType
    , isBFFEndpoint
    , errorPrefixMessage
}: makeAPICallProps<TPayload> ): Promise<TData> => {

    const isPreview = process.env.NEXT_PUBLIC_IS_PREVIEW === 'true';
    const isPreviewAndNotBFF = isPreview && !isBFFEndpoint;

    const baseURL = isPreviewAndNotBFF
        ? process.env.NEXT_PUBLIC_PREVIEW_BE_URL
        : process.env.NEXT_PUBLIC_BE_URL;

    try {
        const response = await axiosInstance( {
            method: method || 'GET'
            , url
            , baseURL
            , data
            , cancelToken
            , responseType: responseType || 'json'
            , headers: isBFFEndpoint ? { 'Authorization': `JWT ${ process.env.NEXT_PUBLIC_BFF_KEY }` } : {}
        } );
        return response ? response.data : null;
    } catch ( e: any ) {
        let message = 'We experienced a network error. Please try again or contact support.';
        const response = e.response;
        if (
            url
            && url.indexOf( '/users/login' ) === -1
            && url.indexOf( '/users/check' ) === -1
            && url.indexOf( '/users/reset-password' ) === -1
            && response
            && response.status
            && ( response.status === 401 || response.status === 403 )
        ) {
            if ( response.data.code === 'SESSION_NOT_FOUND' ) {
                const store = window.store;
                store?.dispatch( updateSessionErrorCount() );
            }
            console.error( 'Unauthorized to retrieve data', response, window.location.pathname );
        }

        if ( response && response.data && ( response.data.clientMessage || response.data.message ) ) {
            message = ( response.data.clientMessage || response.data.message );
            if ( message.includes( '-' ) ) {
                const messageArr = message.split( '-' );
                message = messageArr[ messageArr.length - 1 ];
            }
        }

        throw new Error(
            errorPrefixMessage
                ? `${ errorPrefixMessage } - ${ message }`
                : message
        );
    }
};
export default axiosInstance;
